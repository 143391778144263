@import url(https://use.fontawesome.com/releases/v5.15.0/css/all.css);
@import url(https://use.fontawesome.com/releases/v5.15.0/css/v4-shims.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800);

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #003a72;
  --info: #9dc328;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.35rem;
  font-family: Open Sans;
  font-weight: 500;
  line-height: 1.2;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.row > .col,
.row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 58, 114, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #003a72;
  border-color: #003a72;
}

.btn-primary:hover {
  color: #fff;
  background-color: #00274c;
  border-color: #00203f;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 88, 135, 0.5);
  color: #fff;
  background-color: #003a72;
  border-color: #003a72;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #003a72;
  border-color: #003a72;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00203f;
  border-color: #001a32;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 88, 135, 0.5);
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 88, 135, 0.5);
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 88, 135, 0.5);
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #003a72;
  border-color: #003a72;
}

.btn-info {
  color: #212529;
  background-color: #9dc328;
  border-color: #9dc328;
}

.btn-info:hover {
  background-color: #83a321;
  border-color: #7b991f;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 171, 40, 0.5);
  color: #212529;
  background-color: #9dc328;
  border-color: #9dc328;
}

.btn-info.disabled,
.btn-info:disabled {
  color: #212529;
  background-color: #9dc328;
  border-color: #9dc328;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #7b991f;
  border-color: #728e1d;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 171, 40, 0.5);
}

.btn-cinza {
  color: #fff;
  background-color: #2f4d5e;
  border-color: #2f4d5e;
}

.btn-cinza:hover {
  color: #fff;
  background-color: #1e3542;
  border-color: #13232c;
}

.btn-cinza:focus,
.btn-cinza.focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 77, 94, 0.5);
}

.btn-cinza.disabled,
.btn-cinza:disabled {
  color: #fff;
  background-color: #46718a;
  border-color: #46718a;
}

.btn-cinza:not(:disabled):not(.disabled):active,
.btn-cinza:not(:disabled):not(.disabled).active,
.show > .btn-cinza.dropdown-toggle {
  color: #fff;
  background-color: #1e3542;
  border-color: #13232c;
}

.btn-cinza:not(:disabled):not(.disabled):active:focus,
.btn-cinza:not(:disabled):not(.disabled).active:focus,
.show > .btn-cinza.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 77, 94, 0.5);
}

.btn-outline-primary {
  color: #003a72;
  border-color: #003a72;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #003a72;
  border-color: #003a72;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 58, 114, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #003a72;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #003a72;
  border-color: #003a72;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 58, 114, 0.5);
}

.btn-outline-info {
  color: #9dc328;
  border-color: #9dc328;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #9dc328;
  border-color: #9dc328;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 195, 40, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #9dc328;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #9dc328;
  border-color: #9dc328;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 195, 40, 0.5);
}

.btn-outline-cinza {
  color: #2f4d5e;
  border-color: #2f4d5e;
}

.btn-outline-cinza:hover {
  color: #fff;
  background-color: #2f4d5e;
  border-color: #2f4d5e;
}

.btn-outline-cinza:focus,
.btn-outline-cinza.focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 77, 94, 0.5);
}

.btn-outline-cinza.disabled,
.btn-outline-cinza:disabled {
  color: #46718a;
  background-color: transparent;
}

.btn-outline-cinza:not(:disabled):not(.disabled):active,
.btn-outline-cinza:not(:disabled):not(.disabled).active,
.show > .btn-outline-cinza.dropdown-toggle {
  color: #fff;
  background-color: #1e3542;
  border-color: #13232c;
}

.btn-outline-cinza:not(:disabled):not(.disabled):active:focus,
.btn-outline-cinza:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-cinza.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 77, 94, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #003a72;
  text-decoration: none;
}

.btn-link:hover {
  color: #001326;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #003a72;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #003a72;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #001326;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 58, 114, 0.25);
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #003a72;
  border-color: #003a72;
}

.badge-primary {
  color: #fff;
  background-color: #003a72;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #00203f;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 58, 114, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #9dc328;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #212529;
  background-color: #7b991f;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(157, 195, 40, 0.5);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #003a72;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  /* margin-bottom: -1px; */
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #003a72;
  border-color: #003a72;
}

.list-group-item-primary {
  color: #001e3b;
  background-color: #b8c8d8;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #001e3b;
  background-color: #a8bbcf;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #001e3b;
  border-color: #001e3b;
}

.list-group-item-info {
  color: #526515;
  background-color: #e4eec3;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #526515;
  background-color: #dbe8af;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #526515;
  border-color: #526515;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

.bg-cinza {
  background: #2f4d5e !important;
}

.bg-primary {
  background-color: #003a72 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #00203f !important;
}

.bg-info {
  background-color: #9dc328 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #7b991f !important;
}

.border-primary {
  border-color: #003a72 !important;
}

.border-info {
  border-color: #9dc328 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.175rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.175rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.175rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.175rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.175rem !important;
}

.m-2 {
  margin: 0.35rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.35rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.35rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.35rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.35rem !important;
}

.m-3 {
  margin: 0.7rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.7rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.7rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.7rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.7rem !important;
}

.m-4 {
  margin: 1.05rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.05rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.05rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.05rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.05rem !important;
}

.m-5 {
  margin: 2.1rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2.1rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.1rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2.1rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2.1rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.175rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.175rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.175rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.175rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.175rem !important;
}

.p-2 {
  padding: 0.35rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.35rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.35rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.35rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.35rem !important;
}

.p-3 {
  padding: 0.7rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.7rem !important;
}

.pr-3,
.px-3 {
  padding-right: 0.7rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.7rem !important;
}

.pl-3,
.px-3 {
  padding-left: 0.7rem !important;
}

.p-4 {
  padding: 1.05rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.05rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.05rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.05rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.05rem !important;
}

.p-5 {
  padding: 2.1rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2.1rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2.1rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2.1rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2.1rem !important;
}

.m-n1 {
  margin: -0.175rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.175rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.175rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.175rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.175rem !important;
}

.m-n2 {
  margin: -0.35rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.35rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.35rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.35rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.35rem !important;
}

.m-n3 {
  margin: -0.7rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -0.7rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -0.7rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -0.7rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -0.7rem !important;
}

.m-n4 {
  margin: -1.05rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.05rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.05rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.05rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.05rem !important;
}

.m-n5 {
  margin: -2.1rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2.1rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2.1rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2.1rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2.1rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.text-white {
  color: #fff !important;
}

.text-cinza {
  color: #2f4d5e !important;
}

.text-primary {
  color: #003a72 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #001326 !important;
}

.text-info {
  color: #9dc328 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #6a841b !important;
}

@media (max-width: 1199.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
